import styled, { keyframes } from "styled-components";
import FrameBackground from 'assets/images/frame.png';

const ContactStyleWrapper = styled.div`
  font-family: 'Kanit', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; // take the full viewport height
  text-align: center; // center the text horizontally

  
  background: url(${FrameBackground}) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%; /* This should make the background fill the entire div */
  

  .get-in-touch-button {
    cursor: none;
  }

  h1 {
  margin-top: 100px;
}

.content {
  
  margin: 200px; /* Center the content horizontally */
  text-align: center; /* Center the text */
  font-size: 16px; /* Adjust the font size as needed */
  padding: 20px;
  
}

.back-button {
  
  width: 140px;  /* Adjust as needed */
  height: 75px;
  cursor: pointer;
  cursor: none;

  &:hover {
    /* Hover effect here */
    transform: scale(1.1);  /* Scale the element to 110% */
  }
}                

  .contact_info_title {
    font-size: 22px;
    line-height: 40px !important;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 26px;
  }

  .contact_info_item {
    display: flex;
    align-items: baseline;
    column-gap: 20px;

    h6 {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 5px;
    }

    a {
      color: #6d4afe;
    }
  }

  .contact_info_item + .contact_info_item {
    margin-top: 20px;
  }

  @media only screen and (max-width: 767px) {
    .contact_info {
      margin-top: 40px;
    }
  }

   .background-frame {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1rem;
    color: white;
    white-space: nowrap;
  }
`;

// Button styles
export const StyledButton = styled.button`
  font-family: 'Press Start 2P', sans-serif;
  border: none;
  margin-top: 50px;
  margin-bottom: 0px;
  color: white;
  font-size: 1em;
  padding: 0.6em 2em;
  border-radius: 30px;
  cursor: none !important;
  transition: all 0.2s ease;
  outline: none;
  background: linear-gradient(0deg, #fb82a7, #fb43aa, #ee1784);
  color: transparent;
  -webkit-background-clip: text;
  box-shadow: 0 0 10px #fb82a7, 0 0 20px #fb43aa, 0 0 30px #f927ae, 0 0 40px #F29100;
  &:hover {
    box-shadow: 0 0 20px #fb82a7, 0 0 30px #fb43aa, 0 0 40px #f927ae, 0 0 50px #ee1784, 0 0 60px #F29100;
  }
  &:active {
    box-shadow: 0 0 5px #fb82a7, 0 0 10px #fb43aa, 0 0 15px #f927ae, 0 0 20px #F29100;
  }
`;

// Popup modal styles
const StyledModal = styled.div`
  cursor: none !important;
  font-family: 'Press Start 2P', sans-serif;
  background: #000;
  color: #fff;
  width: 500px;
  height: 400px; /* Adjust the height as needed */
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  text-shadow: 0 0 5px #ff00cc, 0 0 10px #ff00cc, 0 0 15px #ff00cc, 0 0 20px #ff00cc;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    flex-direction: column;
    input, textarea {
      margin-top: 20px; /* Add margin to separate the button from the inputs */
      margin-bottom: 10px;
      padding: 10px;
      font-size: 12px;
      cursor: none;
    }
    button[type="submit"] {
      width: 300px;
      cursor: none !important;
      font-size: 16px;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background: #ff00cc;
      color: #fff;
      transition: 0.3s ease-in-out;
      text-shadow: 0 0 5px #ff00cc, 0 0 10px #ff00cc, 0 0 15px #ff00cc, 0 0 20px #ff00cc;
      box-shadow: 0 0 5px #ff00cc, 0 0 15px #ff00cc, 0 0 25px #ff00cc, 0 0 35px #ff00cc;
      margin-top: 50px; /* Adjust this value to add space between the inputs and the button */
      margin-left: auto; /* These will center the button horizontally */
      margin-right: auto;
      :hover {
        background: #04d9ff;
        text-shadow: 0 0 5px #04d9ff, 0 0 10px #04d9ff, 0 0 15px #04d9ff, 0 0 20px #04d9ff;
        box-shadow: 0 0 5px #04d9ff, 0 0 15px #04d9ff, 0 0 25px #04d9ff, 0 0 35px #04d9ff;
      }
    }
  }
`;

const CloseButton = styled.button`
  cursor: none !important;
  position: absolute;
  top: 0px;
  right: 5px;
  padding: 5px;
  font-size: 15px;
  background-color: transparent;
  color: pink;
  border: none;
  z-index: 1; 
`;

export { ContactStyleWrapper, StyledModal, CloseButton };
