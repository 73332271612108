import React, { useEffect, useRef } from 'react';
import './ArcadeBackground.css';
import homebg from '../assets/videos/homebg.mp4';



const ArcadeBackground = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        let canvas = canvasRef.current;
        let c = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        window.addEventListener('resize', () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });

        let HorizontalArray = [];
        function Horizontal(y) {
            this.y = y;
            this.dy = 0.8;
            this.opacity = 0;

            this.draw = () => {
                c.beginPath();
                c.lineWidth = 5;
                c.strokeStyle = `rgba(255, 0, 255, ${this.opacity})`;
                c.moveTo(30, this.y);
                c.lineTo(canvas.width, this.y);
                c.stroke();
            }

            this.update = () => {
                if (this.y >= canvas.height) {
                    HorizontalArray.splice(HorizontalArray.indexOf(this), 1);
                }

                this.opacity += 0.003;

                this.dy += 0.04;
                this.y += this.dy;
                this.draw();
            }
        }

        let grad = c.createLinearGradient(0, canvas.height, 0, 0);
        grad.addColorStop("0", "rgba(255, 0, 255, 0.5)");
        grad.addColorStop("0.55", "rgba(255, 0, 255, 0)");
        grad.addColorStop("1.0", "rgba(255, 0, 255, 0)");
        let VerticalArray = [];
        function Vertical(x) {
            this.x = x;

            this.draw = () => {
                c.beginPath();
                c.lineWidth = 2;
                c.strokeStyle = grad;
                c.moveTo(canvas.width / 2, 360);
                c.lineTo(this.x, canvas.height);
                c.stroke();
            }

            this.update = () => {
                this.draw();
            }
        }

        let interval = (canvas.width / 10);
        let cross = 0 - interval * 8;
        for (let i = 0; i < 27; i++) {
            VerticalArray.push(new Vertical(cross));
            cross += interval;
        }

        setInterval(() => {
            HorizontalArray.push(new Horizontal(canvas.height / 2));
        }, 350);

        function animate() {
            requestAnimationFrame(animate);
            c.clearRect(0, 0, canvas.width, canvas.height);

            for (let i = 0; i < HorizontalArray.length; i++) {
                HorizontalArray[i].update();
            }
            for (let i = 0; i < VerticalArray.length; i++) {
                VerticalArray[i].update();
            }
        }
        animate();
    }, []);

    return (
        <div className="canvasContainer">
            <video autoPlay muted loop id="myVideo">
                <source src={homebg} type="video/mp4" />
            </video>
            <canvas ref={canvasRef}></canvas>
        </div>

    );
}

export default ArcadeBackground;
