import styled from "styled-components";

const RoadMapStyleWrapper = styled.div`
  .frame-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .frame-container img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export default RoadMapStyleWrapper;
