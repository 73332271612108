import React from 'react';
import { Link } from 'react-router-dom';
import './NeonMenu.css';

const NeonMenu = () => {
    return (
        <div className="neon-menu-container">
            <ul>
            <li style={{ "--clr": "#00ade1" }}>
                    <Link to="/arcade-hub" data-text="&nbsp;Arcade_Hub">&nbsp;Arcade_Hub&nbsp;</Link>
            </li>
            <li style={{ "--clr": "#ffdd1c" }}>
                <Link to="/roadmap" data-text="&nbsp;Roadmap">&nbsp;ROADMAP&nbsp;</Link>
            </li>
            <li style={{ "--clr": "#00dc82" }}>
                <Link to="/team" data-text="&nbsp;Team">&nbsp;TEAM&nbsp;</Link>
            </li>
            <li style={{ "--clr": "#dc00d4" }}>
                <Link to="/contact" data-text="&nbsp;Contact">&nbsp;CONTACT&nbsp;</Link>
            </li>
            </ul>
        </div >
    );
};

export default NeonMenu;
