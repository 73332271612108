import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import frameImage from 'assets/images/frame.png';
import FAQItem from './FAQItem';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;


// Define styled button component
const CloseButton = styled.button`
  position: absolute;
  right: 13%;
  top: 5%;
  background: none;
  border: none;
  color: #AE0DF5; // Neon purple
  font-size: 1.5em;
  cursor: none;
  padding: 10px 20px;
  border-radius: 50px; // Rounded corners for cloud-like shape
  box-shadow: 0px 0px 15px 5px #AE0DF5; // Neon purple glow

  // Change color on hover
  &:hover {
    color: #4DD2FF; // Neon light blue
    box-shadow: 0px 0px 15px 5px #4DD2FF; // Neon light blue glow
    cursor: none;
  }
`;

const FAQModal = ({ isOpen, onRequestClose }) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 1,
        },
        content: {
            cursor: 'none',
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            background: `url(${frameImage}) no-repeat center center`,
            backgroundSize: `${windowSize.width}px ${windowSize.height}px`,
            color: '#fff',
            padding: '15px',
            border: '2px solid #fff',
            borderRadius: '10px',
            width: '100%', // 100% of viewport width
            height: '100%', // 100% of viewport height
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
        >
            <CloseButton onClick={onRequestClose}>X</CloseButton>
            
            <FAQItem question="How do I participate in the Beta phase of your Pong game?" answer="To participate, you must possess a WL role, be at least level 12 on our Discord server, and be an active community member on Discord and Twitter." />
            <FAQItem question="What benefits do I get as an NFT holder of your project?" answer="As an NFT holder, you'll enjoy revenue shares, reduced/free service use, gain multipliers in our PvE games, and more." />
            <FAQItem question="Can you explain the Play2Earn system in your PvE games?" answer="In our single-player games, you can earn game coins while playing. The methods of earning differ based on the game." />
            <FAQItem question="How does the pawnshop service work for NFT holders?" answer="You can pledge your NFT as collateral to receive a loan equal to 50% of its current market value. Repay the loan plus a 18.88% fee within one week to reclaim your NFT." />
            <FAQItem question="What is the LevelUp zone, and how does Move2Earn function?" answer="LevelUp Zone offers psychological support and a supportive community. The Move2Earn system lets users earn Meta Arcade Coin by engaging in physical activities tracked by the platform." />
            <FAQItem question="How can I participate in the Leisure Raffle and what are the prizes?" answer="Participate in the Leisure Raffle by purchasing one of the 3333 available tickets. Prizes include all-expenses-paid world trips for 33 lucky winners." />
            <FAQItem question="Are there any rewards for top players in your PvP games?" answer="Yes, top players are rewarded through tournaments, leaderboards, collaborations, and more." />
            <FAQItem question="What other services can we expect from your utility project in the future?" answer="Future services may include different types of events, metaverse, real-life payments with Meta Arcade Coin, etc." />
            <FAQItem question="How can I stay updated on the launch of new games and services?" answer="Stay updated by following our Twitter, being active on our Discord, and checking the announcement channel." />
            <FAQItem question="Is your platform compatible with both Web3 and Web2 communities?" answer="Yes, our platform is compatible with both Web3 and Web2�communities." />
            {/* More questions and answers... */}
        </Modal>
    );
};

export default FAQModal;