// ContactFormModal.jsx:
import React, { useState } from 'react';
import { StyledModal, CloseButton } from "../Contact.style";


function ContactFormModal({ closeModal }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [discordId, setDiscordId] = useState("");
    const [twitterId, setTwitterId] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        // This is a placeholder for form submission logic
        alert(`Message from ${firstName} ${lastName} sent!`); // Show a simple alert for now
        closeModal(); // Close the modal
    };

    return (
        <StyledModal>
            <CloseButton onClick={closeModal}>X</CloseButton>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="discordId"
                    placeholder="Discord ID"
                    required
                    onChange={(e) => setDiscordId(e.target.value)}
                />
                <input
                    type="text"
                    name="twitterId"
                    placeholder="Twitter ID"
                    required
                    onChange={(e) => setTwitterId(e.target.value)}
                />
                <textarea
                    name="message"
                    placeholder="Message"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">Send</button>
            </form>
        </StyledModal>
    );
}

export default ContactFormModal;