const data = [
  {
    rank: "01",
    name: "RolexDeo",
    key: "0x95e441....ddd97400",
    locked: "365 Days",
    balance: "$305,626.99",
  },
  {
    rank: "02",
    name: "Doris S. Bohr",
    key: "0x842db9....c03d141f",
    locked: "185 Days",
    balance: "$50,899.00",
  },
  {
    rank: "03",
    name: "Melissa Freeman",
    key: "0x8de8be....48be4556",
    locked: "90 Days",
    balance: "$258,69.28",
  },
  {
    rank: "04",
    name: "HazelFeltman",
    key: "0xb83166....a9a8dc0d",
    locked: "60 Days",
    balance: "$33,855.90",
  },
  {
    rank: "05",
    name: "Sally A. Martinez",
    key: "0x8637ab....a1b8674a",
    locked: "365 Days",
    balance: "$305,626.99",
  },
  {
    rank: "06",
    name: "Hilda Dekker",
    key: "0x95e441....ddd97400",
    locked: "365 Days",
    balance: "$305,626.99",
  },
  {
    rank: "07",
    name: "Jennifer Pulranko",
    key: "0xb32cae....d2879700",
    locked: "285 Days",
    balance: "$896,626.00",
  },
  {
    rank: "08",
    name: "Ann M. Marks",
    key: "0x95e441....ddd97400",
    locked: "90 Days",
    balance: "$305,626.99",
  },
  {
    rank: "09",
    name: "LeonardParker",
    key: "0x864d68....a0b805b8",
    locked: "30 Days",
    balance: "$22,852.90",
  },
  {
    rank: "10",
    name: "Debra L. Duncan",
    key: "0x8504e3....7d3b33b2",
    locked: "365 Days",
    balance: "$305,626.55",
  },
  {
    rank: "11",
    name: "Robert Daneil",
    key: "0x8504e3....7d3b33b2",
    locked: "156 Days",
    balance: "$220,580.55",
  },
  {
    rank: "12",
    name: "Roe De. Costa",
    key: "0x8504e3....7d3b33b2",
    locked: "90 Days",
    balance: "$888,25.00",
  },
  {
    rank: "13",
    name: "Leonel D Prince",
    key: "0x8504e3....7d3b33b2",
    locked: "58 Days",
    balance: "$305,626.99",
  },
  {
    rank: "14",
    name: "Cristiano",
    key: "0x8504e3....7d3b33b2",
    locked: "60 Days",
    balance: "$58,855.00",
  },
  {
    rank: "15",
    name: "Dani Alves",
    key: "0x8504e3....7d3b33b2",
    locked: "365 Days",
    balance: "$966,058.88",
  },
];

export default data;
