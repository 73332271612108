// TeamDetails.jsx
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { TeamDetailsStyleWrapper, TeamDetailsWrapper } from './TeamDetails.style';
import { Link } from 'react-router-dom';
import teambg from '../../assets/videos/teambg.mp4';
import { VideoBackground } from './TeamDetails.style';
import thumb from "assets/images/team/jeChilly.jpg";
import thumb2 from "assets/images/team/Munu.jpg";
import thumb3 from "assets/images/team/AsterX.jpg";
import thumb4 from "assets/images/team/Vlad.jpg";
import thumb5 from "assets/images/team/Razvan.jpg";
import thumb6 from "assets/images/team/RaDesign.jpeg";
import thumb7 from "assets/images/team/Ciprian.jpg";
import thumb8 from "assets/images/team/Chef.png";
import thumb9 from "assets/images/team/Hussayn.jpg";
import thumb10 from "assets/images/team/VP.png";
import fbIcon from "assets/images/icons/facebook.svg";
import linkedIcon from "assets/images/icons/linkedin.svg";
import twitterIcon from "assets/images/icons/twitter.svg";
import frameImage from 'assets/images/frame.png';
import BackButtonImg from 'assets/images/back.png'; // Import the back button image
import dscImage from 'assets/images/dsc.png';
import twImage from 'assets/images/tw.png';
import wpImage from 'assets/images/wp.png';


const wobble = keyframes`
  0% { transform: translate(0, 0); }
  10% { transform: translate(3px, 3px); }
  20% { transform: translate(-3px, 3px); }
  30% { transform: translate(3px, -3px); }
  40% { transform: translate(3px, 3px); }
  50% { transform: translate(3px, 3px); }
  60% { transform: translate(3px, 3px); }
  70% { transform: translate(-3px, 3px); }
  80% { transform: translate(3px, -3px); }
  90% { transform: translate(3px, 3px); }
  100% { transform: translate(0px, 0px); }
`;

const titleGradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  25% { background-position: 25% 75%; }
  50% { background-position: 100% 50%; }
  75% { background-position: 75% 25%; }
  100% { background-position: 0% 50%; }
`;

const generateAnimationDelay = () => {
    return Math.random() * 5; // Adjust the range as per your preference
};

const teamDetailsGradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  25% { background-position: 25% 75%; }
  50% { background-position: 100% 50%; }
  75% { background-position: 75% 25%; }
  100% { background-position: 0% 50%; }
`;


const TitleContainer = styled.h1`
  font-family: 'Press Start 2P', sans-serif;
  z-index: 10;
  font-size: 2.5em;
  color: #FFF;
  text-align: center;
  background: linear-gradient(-45deg, #ff54d5, #ff8de3, #d1b9ff, #8fdffd);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${titleGradientAnimation} 7s ease infinite;

  display: flex;      // Added these lines
  justify-content: center;
  align-items: center;
`;

const MemberInfo = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  border-radius: 5px;
  width: 500px;
  text-align: center;
  z-index: 9999;

  .popup-text {
    font-size: 14px; // Adjust the font size as desired
    margin-top: 0.8rem; // Add spacing between paragraphs
  }
`;

const StyledImgContainer = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  overflow: hidden;
  animation: ${wobble} 10.5s infinite;
  animation-delay: ${props => props.animationDelay}s;

  display: flex;      // Added these lines
  justify-content: center;
  align-items: center;
  margin: 15px;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TeamMember = ({ index, name, role, img, bio, top, left }) => {
    const [show, setShow] = useState(false);

    return (
        <div
            className="team-member"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            style={{ position: "relative" }}  // Added this line
        >
            <StyledImgContainer
                index={index}
                animationDelay={generateAnimationDelay()}
                top={top}
                left={left}
            >
                <StyledImg src={img} alt={name} />
            </StyledImgContainer>
            <MemberInfo show={show}>
                <h3>{name}</h3>
                <p>{role}</p>
                <div className="popup-text">{bio}</div>
            </MemberInfo>
        </div>
    );
};

const teamMembers = [
    {
        name: "jeChilly",
        role: "Founder",
        img: thumb,
        experience: "10+ Years",
        email: "mailme@domain.com",
        phone: "+56 00 9834 558",
    },
    {
        name: "Munu",
        role: "Co-Founder",
        img: thumb2,
        experience: "8+ Years",
        email: "john@example.com",
        phone: "+1 987 654 3210",
    },
    {
        name: "AsterX",
        role: "Co-Founder",
        img: thumb3,
        experience: "6+ Years",
        email: "jane@example.com",
        phone: "+1 555 123 4567",
    },
    {
        name: "Vlad Grigore",
        role: "Event Manager",
        img: thumb4,
        experience: "12+ Years",
        email: "johnsmith@example.com",
        phone: "+1 123 456 7890",
    },
    {
        name: "Razvan",
        role: "Frontend Developer",
        img: thumb5,
        experience: "7+ Years",
        email: "sarah@example.com",
        phone: "+1 987 654 3210",
    },
    {
        name: "Virtual Partener",
        role: "Marketing",
        img: thumb10,
        experience: "7+ Years",
        email: "sarah@example.com",
        phone: "+1 987 654 3210",
    },
    {
        name: "RaDesign",
        role: "Graphic Designer",
        img: thumb6,
        experience: "5+ Years",
        email: "michael@example.com",
        phone: "+1 555 123 4567",
    },
    {
        name: "Ciprian",
        role: "3D Graphic Designer",
        img: thumb7,
        experience: "9+ Years",
        email: "emily@example.com",
        phone: "+1 123 456 7890",
    },
    {
        name: "Carlous.eth",
        role: "Advisor",
        img: thumb8,
        experience: "8+ Years",
        email: "david@example.com",
        phone: "+1 987 654 3210",
    },
    {
        name: "Hussayn",
        role: "Full-Stack Game Dev",
        img: thumb9,
        experience: "8+ Years",
        email: "david@example.com",
        phone: "+1 987 654 3210",
    },
];

const TeamDetails = () => {

    const handleGoBack = () => {
        window.history.back(); // Go back to the previous page
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderTeamMembers = () => {
        return teamMembers.map((member, index) => (
            <TeamMember
                index={index}
                key={index}
                name={member.name}
                role={member.role}
                img={member.img}
                bio={member.bio}
                top={member.top}  // Added this line
                left={member.left}  // Added this line
            />
        ));
    };

    const group1 = teamMembers.slice(0, 5);
    const group2 = teamMembers.slice(5, 10);

    return (
        <TeamDetailsStyleWrapper bgVideo={teambg}>
            <VideoBackground autoPlay loop muted src={teambg} type="video/mp4" />
            <div className="title-container">
                <TitleContainer className="title">Meet the Team</TitleContainer>
                <Link to="/">
                    <img src={BackButtonImg} alt="Back to home" className="back-button" />
                </Link>
            </div>
            <div className="frame-background"
                style={{
                    backgroundImage: `url(${frameImage})`,
                    backgroundSize: `${windowSize.width}px ${windowSize.height}px`,
                }}
            />
            <div className="team-members-container">
                <div className="team-members team-members-left">
                    {group1.map((member, index) => (
                        <TeamMember
                            index={index}
                            key={index}
                            name={member.name}
                            role={member.role}
                            img={member.img}
                            bio={member.bio}
                        />
                    ))}
                </div>
                <div className="team-members team-members-right">
                    {group2.map((member, index) => (
                        <TeamMember
                            index={index + 5}
                            key={index + 5}
                            name={member.name}
                            role={member.role}
                            img={member.img}
                            bio={member.bio}
                        />
                    ))}
                </div>
                <a
                    className="dsc-image"
                    href="https://discord.gg/gNU6xj6tPf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '48%',
                        top: '82%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${dscImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />

                <a
                    className="tw-image"
                    href="https://twitter.com/metaarcadeclub"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '44%',
                        top: '82%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${twImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />

                <a
                    className="wp-image"
                    href="https://meta-arcade-club.gitbook.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '52%',
                        top: '82%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${wpImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '1',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />
            </div>
        </TeamDetailsStyleWrapper>
    );
};

export default TeamDetails;