import numberImg1 from "assets/images/icons/One.svg"
import numberImg2 from "assets/images/icons/two.svg"
import numberImg3 from "assets/images/icons/three.svg"

const data = [
    {
        icon: numberImg1,
        title: "Submit KYC",
        text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece"
    },
    {
        icon: numberImg2,
        title: "Verify Wallet",
        text: "Popular belief Ipsum is not simply random text. It has roots in a piece of classical"
    },
    {
        icon: numberImg3,
        title: "Start Staking",
        text: "Belief norem Isum is not simply random text. It has roots in a piece of classical"
    },
]

export default data;