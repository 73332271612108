import styled from 'styled-components';
import teambg from '../../assets/videos/teambg.mp4';

export const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  margin-top: 2%;
`;

export const TeamDetailsStyleWrapper = styled.div`
  position: relative;
  overflow: hidden; // Added this line
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; // Fill the full width of the container
  height: 100%; // Fill the full height of the container
  
  .title-container {
    display: flex;
    justify-content: center; // Center the title horizontally
    width: 100%; // Fill the full width of the container
    margin-bottom: 2rem; // You can adjust this to create more space between title and members
    margin-top: -750px;

    .title {
      text-align: center; // Center the text within the title container
    }
  }

  .back-button {
  position: relative;
  width: 140px; /* Set the desired width value */
  height: 75px;
  top: -30%;
  left: -472%;
  cursor: none;

  &:hover {
    /* Hover effect here */
    transform: scale(1.1);  /* Scale the element to 110% */
  }
}

  .team-members-container {
    display: flex;
    align-items: center;
    justify-content: center; // Center the members horizontally
    flex-direction: row;
    flex-wrap: wrap; // Allow the members to wrap onto the next line if necessary
    margin-top: 640px;
    margin-bottom: 200px;


  }

  .team-members {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem; // You can adjust this to create space around each member
    text-align: center; // Center the text within each member container
    z-index: 1;
  }

    .team-member-image {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    border-radius: 60%;
    z-index: 1;

      img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      transition: transform 0.3s ease-in-out;
    }

    &:hover img {
      transform: scale(1.2);
    }
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-start; /* Align items to the start on smaller screens */
  }
`;

export const TeamDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 70px 0 120px 0;
  @media only screen and (max-width: 912px) {
    padding: 50px 0 80px 0;
  }
  @media only screen and (max-width: 568px) {
    padding: 40px 0 60px 0;
  }
`;

export default TeamDetailsStyleWrapper;