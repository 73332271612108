import { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GlobalStyles from "assets/styles/GlobalStyles";
import Layout from "components/layout";
import { useModal } from "utils/ModalContext";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import BackButtonImg from 'assets/images/back.png';
import VideoBg from "assets/videos/arcadehubbg.mp4";
import VideoBg2 from "assets/videos/teambg.mp4";
import FrameImg from "assets/images/frame.png";
import dscImage from 'assets/images/dsc.png';
import twImage from 'assets/images/tw.png';
import wpImage from 'assets/images/wp.png';
import Image1 from "assets/images/upcomingevents.png";
import Image2 from "assets/images/upcomingtournaments.png";

const Frame = styled.div`
    background: url(${FrameImg}) no-repeat center center fixed; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
`;

const FrameImage = styled.img`
    position: relative; // or absolute, depending on your layout
    z-index: 0;
    width: 100%;
    height: 100%;
`;

const Video = styled.video`
    position: absolute;
    right: 0%;
    bottom: 20%;
    width: 100%;
    height: 80%;
    max-width: 120%; 
    max-height: 100%;
    z-index: -1;
    margin-left: 10%;
`;

const Video2 = styled.video`
    position: absolute;
    right: -30%;
    bottom: -0%;
    top: -1%;
    min-width: 50%; 
    max-height: 200%;
    height: 160%;
    z-index: -1;
    opacity: 0.7;
`;

const GridContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    padding: 20px;
`;

const HoverImage = styled.img`
    width: 300px;
    object-fit: contain;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: none;
    z-index: 999;
    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 10px 2px rgba(128, 0, 128, 1.6);
    }
`;

export default function ProjectGridPage() {
    const { walletModalvisibility, metamaskModal } = useModal();
    return (
        <Fragment>
            <GlobalStyles />
            <Video autoPlay loop muted>
                <source src={VideoBg} type="video/mp4" />
            </Video>
            <Video2 autoPlay loop muted> // New video element
                <source src={VideoBg2} type="video/mp4" />
            </Video2>
            <Frame />
            <Layout>
                {walletModalvisibility && <WalletModal />}
                {metamaskModal && <MetamaskModal />}
                <h1 style={{ color: '#fff', textAlign: 'center', paddingTop: '140px' }}>Arcade Hub</h1>
                <Link to="/">
                    <img style={{ cursor: 'none', top: '5%' }} src={BackButtonImg} alt="Back to home" className="back-button" />
                </Link>

                

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '360px', height: '100vh' }}>
                    <GridContainer>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '250px', height: '250px', margin: '20px' }}>
                            <Link to="#">
                                <HoverImage src={Image1} alt="Description of Image 1" />
                                <p style={{ color: '#fff', textAlign: 'center', font: 'Kanit' }}>Upcoming Events</p>
                            </Link>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '250px', height: '250px', margin: '20px' }}>
                            <Link to="#">
                                <HoverImage src={Image2} alt="Description of Image 2" />
                                <p style={{ color: '#fff', textAlign: 'center', font: 'Kanit' }}>Upcoming Tournaments</p>
                            </Link>
                        </div>
                    </GridContainer>
                </div>
                <a
                    className="dsc-image"
                    href="https://discord.gg/gNU6xj6tPf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '48%',
                        top: '73%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${dscImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />

                <a
                    className="tw-image"
                    href="https://twitter.com/metaarcadeclub"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '44%',
                        top: '73%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${twImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />

                <a
                    className="wp-image"
                    href="https://meta-arcade-club.gitbook.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '52%',
                        top: '73%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${wpImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '1',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />
            </Layout>
        </Fragment>
    );
}
