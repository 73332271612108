import React from 'react';
import ReactPlayer from 'react-player';

class VideoPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: true,
            promptVisible: false,
            playedSeconds: 0,
            isPopupActive: true,
        };
        this.videoDuration = 0;
        this.lastPausedAt = 0;
    }
    handleVideoReady = () => {
        this.setState({
            playing: true
        });
    };

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
        this.toggleScroll(); // Call the toggleScroll method
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        this.toggleScroll(); // Call the toggleScroll method
    }

    toggleScroll = () => {
        const { isPopupActive } = this.state;
        if (isPopupActive) {
            document.body.style.overflow = 'hidden'; // Disable scroll
        } else {
            document.body.style.overflow = ''; // Enable scroll
        }
    };

    handleKeyDown = (event) => {
        if (this.state.promptVisible && event.code === 'Space') {
            this.setState(
                {
                    promptVisible: false,
                    playedSeconds: this.state.playedSeconds + 1,
                },
                () => {
                    this.setState({
                        playing: true,
                    });
                }
            );
        }
    };

    handleVideoProgress = (state) => {
        const playedSeconds = Math.floor(state.playedSeconds);

        if (playedSeconds === 4 || playedSeconds === 8) {
            if (this.state.playing && this.lastPausedAt !== playedSeconds) {
                this.lastPausedAt = playedSeconds;
                this.setState({
                    playing: false,
                    promptVisible: true,
                    playedSeconds: playedSeconds,
                });
            }
        }
    };

    onDuration = (duration) => {
        this.videoDuration = duration;
    };

    handleVideoEnd = () => {
        this.setState(
            {
                isPopupActive: false,
            },
            () => {
                this.toggleScroll(); // Enable scroll after the popup disappears
                this.props.onEnded();
            }
        );
    };

    render() {
        return (
            <div>
                {this.state.isPopupActive && <div className="disable-scroll" />} {/* Add the disable-scroll class */}
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'black',
                        zIndex: 9999,
                    }}
                >
                    <ReactPlayer
                        url="/sp1.mp4"
                        playing={this.state.playing}
                        onEnded={this.handleVideoEnd}
                        onProgress={this.handleVideoProgress}
                        onDuration={this.onDuration}
                        onReady={this.handleVideoReady} // added this line
                        muted={true} // ensure video is muted
                        width="100%"
                        height="auto"
                        style={{
                            minWidth: '100%',
                            minHeight: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    />
                    {this.state.promptVisible && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '65%',
                                right: '15%',
                                color: 'pink',
                                transform: 'translateY(-50%)',
                                fontFamily: '"Press Start 2P", cursive',
                                fontSize: '20px',
                                textShadow: '0 0 5px #FF33FF, 0 0 10px #FF33FF, 0 0 15px #FF33FF, 0 0 20px #FF33FF, 0 0 30px #FF33FF, 0 0 40px #FF33FF',
                                opacity: this.state.promptVisible ? 1 : 0,
                                transition: 'opacity 0.5s',
                            }}
                        >
                            Press "space" to continue
                        </div>
                    )}
                    <div style={{ height: '20px' }} /> {/* This will create space below the video */}
                </div>
            </div>
        );
    }
}

export default VideoPopup;