import React, { useState, useEffect } from 'react';
import { useModal } from 'utils/ModalContext';
import styled, { keyframes } from 'styled-components';
import GlobalStyles from 'assets/styles/GlobalStyles';
import Layout from 'components/layout';
import WalletModal from 'components/modal/walletModal/WalletModal';
import MetamaskModal from 'components/modal/metamaskModal/MetamaskModal';
import VideoPopup from 'components/VideoPopup';
import NeonMenu from 'components/NeonMenu';
import PopupManifesto from 'components/PopupManifesto';
import frame from 'assets/images/frame.png';
import logoImage from 'assets/images/logo.png';
import pongImage from 'assets/images/pong.png';
import dscImage from 'assets/images/dsc.png';
import twImage from 'assets/images/tw.png';
import wpImage from 'assets/images/wp.png';
import 'assets/styles/styles.css';
import ArcadeBackground from 'components/ArcadeBackground';
import FAQModal from 'components/modal/FAQModal';

const HomeOne = () => {
    const { walletModalvisibility, metamaskModal } = useModal();
    const [showVideo, setShowVideo] = useState(true);
    const [showFAQModal, setShowFAQModal] = useState(false);

    const pulse = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  `;

    const FAQButton = styled.button`
    cursor: none;
    position: absolute;
    top: 88%;
    right: 13%;
    z-index: 999;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.3s;
    background: none;
    border: none;
    color: #ae0df5; // Neon purple
    box-shadow: 0px 0px 15px 5px #ae0df5; // Neon purple glow
    animation: ${pulse} 2s infinite ease-in-out; // Pulsating animation

    // Change color on hover
    outline: none; // New line
    &:hover {
      color: #4d4dff; // Neon light blue
      box-shadow: 0px 0px 15px 5px #4DD2FF; // Neon light blue glow
      cursor: none;
    }
  `;

    const handleFAQClick = () => {
        setShowFAQModal(true);
    };

    const handleFAQClose = () => {
        setShowFAQModal(false);
    };

    const handleVideoEnd = () => {
        setShowVideo(false);
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const firstVisit = sessionStorage.getItem('firstVisit');

        if (firstVisit === null) {
            setShowVideo(true);
            sessionStorage.setItem('firstVisit', 'false');
        } else {
            setShowVideo(false);
        }

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePongClick = () => {
        window.location.href = 'https://metaarcadeclub.com/pong/';
    };

    const [showModal, setShowModal] = useState(false);

    const handleLogoClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <ArcadeBackground />
            <Layout>
                <GlobalStyles />
                <style>
                    {`
            @keyframes lightSpeed {
              0% {
                transform: translateX(-50%) rotate(-45deg);
                filter: brightness(1);
              }
              50% {
                filter: brightness(3);
              }
              100% {
                transform: translateX(-50%) rotate(-45deg);
                filter: brightness(1);
              }
            }
          `}
                </style>
                {walletModalvisibility && <WalletModal />}
                {metamaskModal && <MetamaskModal />}
                {showVideo && <VideoPopup onEnded={handleVideoEnd} />}
                <PopupManifesto
                    title="Manifesto"
                    content={[
                        "Step inside, gamers and dreamers alike, In our world, creativity takes flight. Through challenges we rise, side by side in the fight, Our journey together, a spectacular sight!",
                        "In the heart of the metaverse, where pixel meets code, We've built a haven, our unique abode. With games to be played, and stories to be told, In the Meta Arcade Club, watch the future unfold.",
                        "United in purpose, our spirits set free, In the glow of the screens, we find unity. Playing, creating, in shared harmony, In the Meta Arcade Club, we're one big family.",
                        "With tokens and NFTs, our dreams in view, In this game of life, we're making something new. So, join us, friend, there's a place for you, In the Meta Arcade Club, where gaming dreams come true."
                    ]}
                    show={showModal}
                    handleClose={handleModalClose}
                />
                {showFAQModal && <FAQModal isOpen={showFAQModal} onRequestClose={handleFAQClose} />}
                {!showFAQModal && (
                    <FAQButton onClick={handleFAQClick}>FAQ</FAQButton>
                )}

                <div
                    className="frame-background"
                    style={{
                        backgroundImage: `url(${frame})`,
                        backgroundSize: `${windowSize.width}px ${windowSize.height}px`,
                    }}
                >
                    <NeonMenu />
                </div>
                <div
                    className="logo-image"
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '0%',
                        transform: 'translateX(-50%)',
                        width: '300px',
                        height: '300px',
                        backgroundImage: `url(${logoImage})`,
                        backgroundSize: 'cover',
                        borderRadius: '50%',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onClick={handleLogoClick}
                ></div>
                <div
                    className="pong-image"
                    style={{
                        position: 'absolute',
                        left: '75%',
                        top: '60%',
                        transform: 'translateX(-50%) rotate(-45deg)',
                        width: '150px',
                        height: '150px',
                        backgroundImage: `url(${pongImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        animation: 'lightSpeed 2s ease-in-out infinite',
                        filter: 'brightness(1)',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onClick={handlePongClick}
                ></div>
                <div
                    className="dsc-image"
                    style={{
                        position: 'absolute',
                        left: 'calc(16.5% - 37.5px)', // Adjust the left position based on element width
                        top: 'calc(92% - 37.5px)', // Adjust the top position based on element height
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${dscImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onClick={() => {
                        window.location.href = 'https://discord.gg/gNU6xj6tPf'; // Replace with your Discord link
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)'; // Apply scale(1.1) on hover
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)'; // Revert back to original scale on mouse leave
                    }}
                ></div>
                <div
                    className="tw-image"
                    style={{
                        position: 'absolute',
                        left: 'calc(13% - 37.5px)', // Adjust the left position based on element width
                        top: 'calc(92% - 37.5px)', // Adjust the top position based on element height
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${twImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onClick={() => {
                        window.location.href = 'https://twitter.com/metaarcadeclub'; // Replace with your Twitter link
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)'; // Apply scale(1.1) on hover
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)'; // Revert back to original scale on mouse leave
                    }}
                ></div>
                <div
                    className="wp-image"
                    style={{
                        position: 'absolute',
                        left: 'calc(20% - 37.5px)', // Adjust the left position based on element width
                        top: 'calc(92% - 37.5px)', // Adjust the top position based on element height
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${wpImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '1',
                    }}
                    onClick={() => {
                        window.location.href = 'https://meta-arcade-club.gitbook.io/'; // Replace with your WordPress link
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)'; // Apply scale(1.1) on hover
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)'; // Revert back to original scale on mouse leave
                    }}
                ></div>
            </Layout>
        </>
    );
};

export default HomeOne;
