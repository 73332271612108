import React, { useState } from 'react';
import styled from 'styled-components';
import AnswerModal from './AnswerModal';

const StyledFAQItem = styled.div`
    cursor: none;
    margin: 12px 0;
    width: 80%; 
    padding: 10px; 
    text-align: center;

    h3 {
        font-size: 14px;
    }
`;
 
const FAQItem = ({ question, answer }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
    }
    const closeModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    }

    return (
        <StyledFAQItem onClick={openModal}>
            <h3>{question}</h3>
            <AnswerModal isOpen={isModalOpen} onRequestClose={closeModal} answer={answer} />
        </StyledFAQItem>
    );
};

export default FAQItem;
