import React, { useState, useEffect } from 'react';
import { useModal } from 'utils/ModalContext';
import { Link } from 'react-router-dom';
import Layout from 'components/layout';
import GlobalStyles from 'assets/styles/GlobalStyles';
import WalletModal from 'components/modal/walletModal/WalletModal';
import MetamaskModal from 'components/modal/metamaskModal/MetamaskModal';
import frameImage from 'assets/images/frame.png';
import './RoadMapDetails.css';
import styled from 'styled-components';
import BackButtonImg from 'assets/images/back.png'; // Import the back button image
import videoBackground from 'assets/videos/background.mp4';
import VideoBg2 from "assets/videos/teambg.mp4"
import dscImage from 'assets/images/dsc.png';
import twImage from 'assets/images/tw.png';
import wpImage from 'assets/images/wp.png';


const HoverInfo = styled.div`
    position: absolute;
    transform: translateX(${props => props.hoverDirection === 'right' ? '-50%' : '50%'});
    opacity: ${props => props.show ? '1' : '0'};
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1rem;
    border-radius: 5px;
    width: 500px;
    text-align: center;
`;

const Video2 = styled.video`
    position: absolute;
    right: 0%;
    bottom: 0%;
    top: 0%;
    max-width: 140%; 
    max-height: 200%;
    height: 160%;
    z-index: -1;
    opacity: 0.7;
`;

const HoverInfoPhaseI = styled(HoverInfo)`
    top: 50%;
    left: 10%;
`;

const HoverInfoPhaseII = styled(HoverInfo)`
    top: -15%;
    left: -440%;
`;

const HoverInfoPhaseIII = styled(HoverInfo)`
    top: -440%;
    left: 20%;
`;

const HoverInfoPhaseIV = styled(HoverInfo)`
    top: -400%;
    left: -440%;
`;

const Quarter = ({ title, children }) => {
    const [show, setShow] = useState(false);

    let HoverComponent;
    switch (title) {
        case "Phase I":
            HoverComponent = HoverInfoPhaseI;
            break;
        case "Phase II":
            HoverComponent = HoverInfoPhaseII;
            break;
        case "Phase III":
            HoverComponent = HoverInfoPhaseIII;
            break;
        case "Phase IV":
            HoverComponent = HoverInfoPhaseIV;
            break;
        default:
            HoverComponent = HoverInfo;
    }

    return (
        <div className={`quarter ${title.replace(/\s+/g, '-')}`} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <h2>{title}</h2>
            <HoverComponent show={show} className={`hover-info-${title.replace(/\s+/g, '-')}`}>
                <p>{children}</p>
            </HoverComponent>
        </div>
    );
};



const RoadMapDetailsPage = () => {
    const { walletModalvisibility, metamaskModal } = useModal();

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Layout>
            <GlobalStyles />
            {walletModalvisibility && <WalletModal />}
            {metamaskModal && <MetamaskModal />}
            <Link to="/"> 
                <img src={BackButtonImg} alt="Back to home" className="back-button" />
            </Link>
            <video autoPlay muted playsInline className="video-background">
                <source src={videoBackground} type="video/mp4" />
            </video>
            <Video2 autoPlay loop muted> // New video element
                <source src={VideoBg2} type="video/mp4" />
            </Video2>
            <div className="frame-background"
                style={{
                    backgroundImage: `url(${frameImage})`,
                    backgroundSize: `${windowSize.width}px ${windowSize.height}px`,
                }}
            >
                <div className="roadmap-container">
                    <h1 className="title">Roadmap</h1>
                    <Quarter title="Phase I" hoverDirection="right">
                        -Complete the development of 3D Pong, ensuring optimal gameplay and visuals<br />
                        -Upgrade and revamp the Meta Arcade Club website for a smoother user experience<br />
                        -Host a game launch tournament with fantastic prizes to celebrate and engage the community<br />
                        -Implement user testing and feedback mechanisms for continuous improvement<br />
                        -Establish an marketing strategy to promote the game, NFT collection, and community<br />
                        -Initiate social media campaigns to engage initial users and tease upcoming launches
                    </Quarter>
                    <Quarter title="Phase II" hoverDirection="left">
                        -Officially launch the 3D Pong game<br />
                        -Initiate a promotional campaign for the game launch, including sneak peeks, influencer collaborations, and special events<br />
                        -Launch Meta Arcade Club's first NFT collection, featuring unique and limited edition assets<br />
                        -Continue social media campaigns, emphasizing new skins, upcoming games, and the Level Up Zone's benefits<br />
                        -Release additional unique skins for 3D Pong, including limited edition NFT skins for dedicated supporters<br />
                        -Begin the development of new NFT-based arcade games to expand the Meta Arcade Club gaming universe<br />
                        -Organize regular tournaments and events, offering exclusive NFT rewards and other exciting incentives<br />
                        -Establish partnerships with relevant industry players to expand the reach and impact of the project<br />
                        -Implement the Level Up Zone, to encourage continuous user engagement and loyalty<br />
                        -Plan and execute global IRL events to further engage the community and showcase the project's progress
                    </Quarter>
                    <Quarter title="Phase III" hoverDirection="right">
                        -Start organizing Leisure Raffles, offering winners all-expenses-paid trips to outstanding destinations<br />
                        -Initiate a marketing push for the Leisure Raffles, NFT renting, and Pawn Arcade services<br />
                        -Launch the NFT renting and Pawn Arcade services, giving NFT owners more flexibility and encouraging participation in the ecosystem<br />
                        -Begin the production and distribution of Meta Arcade Club merchandise<br />
                        -Leverage merchandise for promotional giveaways and user engagement rewards
                        -Promote the exclusive ticket collection, emphasizing its scarcity and benefits<br />
                        -Launch the exclusive ticket collection, limited to 1111 NFTs. These tickets will offer holders reduced/free prices for our services<br />
                        -Start the development of the Arcadians collection, which will provide exclusive access to our metaverse<br />
                        -Plan and execute a major publicity campaign for the Arcadians collection and upcoming metaverse<br />
                        -Aim for mainstream attention, extending beyond the web3 space
                    </Quarter>
                    <Quarter title="Phase IV" hoverDirection="left">
                        -Launch the Arcadians collection, a unique NFT series that provides exclusive access to the Meta Arcade Club metaverse<br />
                        -Launch a major publicity campaign for the Arcadians collection launch and metaverse development start<br />
                        -Begin the development of the Meta Arcade Club metaverse<br />
                        -Regularly update and add new games to the platform, keeping the content fresh and engaging for users and continue to organize tournaments and events that offer exclusive NFT rewards and other incentives<br />
                        -Maintain regular social media campaigns and user engagement efforts<br />
                        -Continue to organize tournaments and events that offer exclusive NFT rewards and other incentives<br />
                        -Introducing new designs for Meta Arcade Club merchandise or limited edition items in line with special events or launches<br />
                        -Conduct regular reviews and updates of the Level Up Zone<br />
                        -Plan and execute further global IRL events<br />
                        -Regularly assess and refine marketing strategies based on user feedback, engagement metrics, and market trends
                    </Quarter>
                </div>
                <a
                    className="dsc-image"
                    href="https://discord.gg/gNU6xj6tPf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '48%',
                        top: '88%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${dscImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />

                <a
                    className="tw-image"
                    href="https://twitter.com/metaarcadeclub"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '44%',
                        top: '88%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${twImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '0',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />

                <a
                    className="wp-image"
                    href="https://meta-arcade-club.gitbook.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '52%',
                        top: '88%',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `url(${wpImage})`,
                        backgroundSize: 'cover',
                        transition: 'transform 0.5s ease-in-out',
                        cursor: 'none',
                        zIndex: '1',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.transform = 'scale(1.1)';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.transform = 'scale(1)';
                    }}
                />
            </div>
        </Layout>
    );
};

export default RoadMapDetailsPage;