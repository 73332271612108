const data = [
  {
    title: "Checkpoint",
    url: "#Checkpoint",
  },
  {
    title: "Amount Stake",
    url: "#amountStake",
  },
  {
    title: "Pre-authorization",
    url: "#preAuthorization",
  },
  {
    title: "Confirm",
    url: "#confirm",
  }, 
];

export default data;
