import { createGlobalStyle, keyframes } from "styled-components";
import KanitFont from 'assets/fonts/Kanit-Regular.ttf';
import PressStart2PFont from 'assets/fonts/PressStart2P-Regular.ttf';

const GlobalStyles = createGlobalStyle` 


@font-face {
    font-family: 'Kanit';
    src: url(${KanitFont}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Press Start 2P';
    src: url(${PressStart2PFont}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
    
::-moz-selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
}

::selection {
    background: #2D65F8;
    text-shadow: none;
    color: #ffffff;
}

/* -----------------------------------/
    01. Meta Arcade Club General CSS
-------------------------------------*/
html,
body {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7) !important;
    font-family: 'Inter', sans-serif !important;
    line-height: 1.6 !important;
    font-weight: 500 !important; 
    background-color: #151625 !important; 
    display: block !important;
    position: relative; // Add this line
    z-index: 9999 !important;  // Add this line
}


img {
    max-width: 100%;
    height: auto;
}

p {
    font-family: 'Kanit', sans-serif;
    margin: 0 0 26px;
    line-height: 1.8 !important;
}

    h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Press Start 2P', sans-serif;
    color: #ffffff;
    line-height: 1.35 !important;
    font-weight: 400;
    margin: 0 0 26px;
    animation: ${keyframes`
      0% { background-position: 0% 50%; }
      25% { background-position: 25% 75%; }
      50% { background-position: 100% 50%; }
      75% { background-position: 75% 25%; }
      100% { background-position: 0% 50%; }
    `} 7s ease infinite;
    background: linear-gradient(-45deg, #ff54d5, #ff8de3, #d1b9ff, #8fdffd);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

h1 {
    font-size: 50px; 
}

h2 {
    font-size: 36px; 
}

h3 {
    font-size: 30px; 
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

a {
    color: #ffffff;
    transition: all 0.3s ease;
    text-decoration: none !important;
    outline: none;
}

a:active,
a:hover {
    text-decoration: none !important;
    outline: 0 none;
    color: #a3ff12;
}

ul {
    list-style: outside none none;
    margin: 0 !important;
    padding: 0 !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    text-decoration: none;
    box-shadow: none;
}

button {
    border: none;
}

/* css grid */

.grid{
    display: grid;
}
.grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr));
}

.col-start-2{
    grid-column-start: 2;
}
.col-start-3{
    grid-column-start: 3;
}
.col-start-4{
    grid-column-start: 4;
}
.col-start-5{
    grid-column-start: 5;
}
.col-start-6{
    grid-column-start: 6;
}
.col-start-7{
    grid-column-start: 7;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 480px) {
    body {
    font-size: 15px;
}
}


`;

const titleGradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  25% { background-position: 25% 75%; }
  50% { background-position: 100% 50%; }
  75% { background-position: 75% 25%; }
  100% { background-position: 0% 50%; }
`;

export default GlobalStyles;
