import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

Modal.setAppElement('#root'); // Set this to your app root element id

const AnswerModalContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    color: #fff;
`;

const CloseButton = styled.button`
    cursor: none;
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 2em;

    &:hover {
        color: #AE0DF5;
        cursor: none;
    }
`;


const AnswerModal = ({ isOpen, onRequestClose, answer }) => {
    const customStyles = {
        overlay: {
            backgroundColor: 'transparent',
            zIndex: 99, // Let's increase this to be safe
        },
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            background: '#000',
            color: '#fff',
            padding: '20px',
            border: '2px solid #fff',
            borderRadius: '10px',
            width: '40%',
            height: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'none',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="Answer Modal"
        >
            <CloseButton onClick={onRequestClose}>X</CloseButton>
            <AnswerModalContent>{answer}</AnswerModalContent>
        </Modal>
    );
};

export default AnswerModal;
