import thumb1 from "assets/images/icons/Key_icon1.png";
import thumb2 from "assets/images/icons/Key_icon2.png";
import thumb3 from "assets/images/icons/Key_icon3.png";
import thumb4 from "assets/images/icons/Key_icon4.png";

const data = [
  {
    icon: thumb1,
    title: "Decentralized",
    text: "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing",
  },
  {
    icon: thumb2,
    title: "Secure Bridge",
    text: "But I must explain to you how all this mistaken idea of new gem denouncing pleasure and praising pain",
  },
  {
    icon: thumb3,
    title: "Fair Allocations",
    text: "When our power of choice is untrammelled and when nothing prevents our being able to do what we like best",
  },
  {
    icon: thumb4,
    title: "Autopilot Liquidity",
    text: "But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently",
  },
];

export default data;
