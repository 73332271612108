import { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import { useModal } from "utils/ModalContext";
import styled, { keyframes } from 'styled-components';
import Layout from "components/layout";
import GlobalStyles from "assets/styles/GlobalStyles";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import ContactFormModal from "../sections/ContactPage/ContactForm/ContactFormModal";
import { ContactStyleWrapper, StyledButton } from "../sections/ContactPage/Contact.style";
import BackButtonImg from 'assets/images/back.png'; // Import the back button image
import VideoBg from "assets/videos/contactbg.mp4";
import VideoBg2 from "assets/videos/homebg.mp4";
import dscImage from 'assets/images/dsc.png';
import twImage from 'assets/images/tw.png';
import wpImage from 'assets/images/wp.png';

const Video = styled.video`
    position: fixed;
    right: 10%;
    bottom: 0%;

    min-width: 50%; 
    max-height: 100%;
    z-index: -1;
    margin-left: 10%;
`;

const Video2 = styled.video`
    position: fixed;
    right: -20%;
    bottom: -0%;
    top: -1%;
    min-width: 50%; 
    max-height: 200%;
    height: 160%;
    z-index: -1;
    opacity: 0.7;
`;

const ContentWrapper = styled.div`
    max-width: 1100px;  // set it to your desired maximum width
    margin: auto;
`;

export default function ContactPage() {
    const { walletModalvisibility, metamaskModal } = useModal();
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <Fragment>
            <Layout>
                <GlobalStyles />
                <Video autoPlay loop muted>
                    <source src={VideoBg} type="video/mp4" />
                </Video>
                <Video2 autoPlay loop muted> // New video element
                    <source src={VideoBg2} type="video/mp4" />
                </Video2>
                {walletModalvisibility && <WalletModal />}
                {metamaskModal && <MetamaskModal />}
                <ContactStyleWrapper>
                    <h1 className="contact_title">Get in Touch</h1>
                    <Link to="/"> 
                        <img src={BackButtonImg} alt="Back to home" className="back-button" />
                    </Link>
                    <ContentWrapper>                        
                        <p>
                            Got a question, concern, or just want to say hi? We're all ears! At the Meta Arcade, we're all about community and connection, so we'd love to hear from you. There are a few ways you can reach us.
                        </p>
                        <p>
                            First, you can send us an email at <a href="mailto:contact@metaarcadeclub.com">contact@metaarcadeclub.com</a>. Our team will be happy to get back to you as soon as possible. Or, if you prefer, you can hop on over to our Discord server and join the conversation. Our Discord server is the perfect place to get in touch with other NFT gaming enthusiasts and connect with the Meta Arcade team.
                        </p>
                        <p>
                            So whether you have a question about our games, want to share some feedback, or just want to chat with us, we're here and ready to listen. Reach out to us today and let's start a conversation!
                        </p>
                        <StyledButton onClick={handleButtonClick} className="styled-button">Get in Touch</StyledButton>
                    </ContentWrapper>
                    <a
                        className="dsc-image"
                        href="https://discord.gg/gNU6xj6tPf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            left: '48%',
                            top: '88%',
                            width: '75px',
                            height: '75px',
                            backgroundImage: `url(${dscImage})`,
                            backgroundSize: 'cover',
                            transition: 'transform 0.5s ease-in-out',
                            cursor: 'none',
                            zIndex: '0',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.transform = 'scale(1.1)';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.transform = 'scale(1)';
                        }}
                    />

                    <a
                        className="tw-image"
                        href="https://twitter.com/metaarcadeclub"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            left: '44%',
                            top: '88%',
                            width: '75px',
                            height: '75px',
                            backgroundImage: `url(${twImage})`,
                            backgroundSize: 'cover',
                            transition: 'transform 0.5s ease-in-out',
                            cursor: 'none',
                            zIndex: '0',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.transform = 'scale(1.1)';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.transform = 'scale(1)';
                        }}
                    />

                    <a
                        className="wp-image"
                        href="https://meta-arcade-club.gitbook.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            left: '52%',
                            top: '88%',
                            width: '75px',
                            height: '75px',
                            backgroundImage: `url(${wpImage})`,
                            backgroundSize: 'cover',
                            transition: 'transform 0.5s ease-in-out',
                            cursor: 'none',
                            zIndex: '1',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.transform = 'scale(1.1)';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.transform = 'scale(1)';
                        }}
                    />
                </ContactStyleWrapper>
                {showModal && <ContactFormModal closeModal={closeModal} />}
            </Layout>
        </Fragment>
    );
}
