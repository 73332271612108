import React, { useState, useEffect } from "react";
import "./CustomCursor.css";



function CustomCursor() {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event) => {
            const { pageX, pageY } = event;
            setPosition({ x: pageX, y: pageY });
        };

        document.addEventListener("mousemove", handleMouseMove);
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <div
            className="custom-cursor"
            style={{ left: position.x, top: position.y }}
        />
    );
}

export default CustomCursor;
