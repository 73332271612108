import React, { useState, useEffect } from 'react';
import './PopupManifesto.css';
import frameImage from 'assets/images/frame.png';

const PopupManifesto = ({ title, content, backgroundImage, show, handleClose }) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    if (!show) return null;

    const modalStyle = {
        background: `rgba(0, 0, 0, 0.9) url(${backgroundImage})`,
        backgroundSize: 'cover',
    };

    const frameStyle = {
        backgroundImage: `url(${frameImage})`,
        backgroundSize: `${windowSize.width}px ${windowSize.height}px`,
    };

    return (
        
        <div className={show ? 'popup-modal show' : 'popup-modal'} style={modalStyle}>
            <div className="popup-content">
                <span className="popup-modal-close" onClick={handleClose}>
                    &times;
                </span>
                <div className="title-container">
                    <h2>{title}</h2>
                </div>
                <div className="text-container">
                    <div className="text-box">{content[0]}</div>
                    <div className="text-box">{content[1]}</div>
                    <div className="text-box">{content[2]}</div>
                    <div className="text-box">{content[3]}</div>
                </div>
                <div className="frame-background"
                    style={{
                        backgroundImage: `url(${frameImage})`,
                        backgroundSize: `${windowSize.width}px ${windowSize.height}px`,
                    }}
                >
                </div>
            </div>
        </div>
    );
};

export default PopupManifesto;
