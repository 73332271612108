import tokenIcon1 from "assets/images/icons/BlockChain1.png"
import tokenIcon2 from "assets/images/icons/BlockChain2.png"
import tokenIcon3 from "assets/images/icons/BlockChain3.png"
import tokenIcon4 from "assets/images/icons/BlockChain4.png"
import tokenIcon5 from "assets/images/icons/BlockChain5.png"
import tokenIcon6 from "assets/images/icons/BlockChain2.png"
import tokenIcon7 from "assets/images/icons/BlockChain4.png"

const data = [ tokenIcon1, tokenIcon2, tokenIcon3, tokenIcon4, tokenIcon5, tokenIcon6, tokenIcon7]

export default data;